import { getCountriesList } from '@/services/countries/countries'
import {
  assigneeOptions,
  contactsStatusOptions,
  organizationTypesOptions,
  createdByOptions,
  activityOptions,
  ticketsStatusOptions,
  reportsNumbersOptions,
  reportsChannelsOptions,
  boardOptions,
  pipelinesOptions,
  campaignStatusOptions,
  campaignSentMessagesOptions
} from '@/definitions/_general/_data/optionsList'
import { getAssociateOptions } from '@/mock/associateCategories'
import type { FieldType, FilterType } from '@/definitions/shared/filters/types'
const allCountries = getCountriesList()
const associateOptions = getAssociateOptions()

export const addContactFilters: FieldType[] = [
  {
    icon: 'text_fields',
    label: 'Full name',
  },
  {
    icon: 'call',
    label: 'Phone',
  },
  {
    icon: 'alternate_email',
    label: 'Email',
  },
  {
    icon: 'business',
    label: 'Organization',
  },
  {
    icon: 'text_fields',
    label: 'Job title',
  },
  {
    icon: 'tmi-form-textbox',
    label: 'Lists',
  },
  {
    icon: 'tmi-form-textbox',
    label: 'Tags',
  },
  {
    icon: 'share',
    label: 'Social media',
  },
  {
    icon: 'tmi-form',
    label: 'Contact type',
  },
  {
    icon: 'account_circle',
    label: 'Contact owner',
  },
  {
    icon: 'tmi-form-textbox',
    label: 'Segments',
  },
  {
    icon: 'tmi-form',
    label: 'Timezone',
  },
  {
    icon: 'tmi-form',
    label: 'Country',
  },
  {
    icon: 'tmi-form',
    label: 'Source',
  },
  {
    icon: 'tmi-form',
    label: 'Lead status',
  },
  {
    icon: 'account_circle',
    label: 'Followers',
  },
  {
    icon: 'tmi-123',
    label: 'Total interactions',
  },
  {
    icon: 'event',
    label: 'Last interaction',
  },
  {
    icon: 'event',
    label: 'Last outbound message',
  },
  {
    icon: 'event',
    label: 'Last inbound message',
  },
  {
    icon: 'tmi-123',
    label: 'Total conversations',
  },
  {
    icon: 'tmi-123',
    label: 'Open conversations',
  },
  {
    icon: 'tmi-123',
    label: 'ID',
  },
  {
    icon: 'event',
    label: 'Created',
  },
  {
    icon: 'account_circle',
    label: 'Created by',
  },
  {
    icon: 'event',
    label: 'Last updated',
  },
  {
    icon: 'account_circle',
    label: 'Last updated by',
  },
  {
    icon: 'radio_button_checked',
    label: 'Field for a radio button',
  },
  {
    icon: 'check_box',
    label: 'Field for checkboxes',
  },
  {
    icon: 'notes',
    label: 'Field for text area',
  },
  {
    icon: 'tmi-money-cash-multiple',
    label: 'Field for money/currency',
  },
  {
    icon: 'schedule',
    label: 'Field for time',
  },
  {
    icon: 'timelapse',
    label: 'Field for time range',
  },
  {
    icon: 'date_range',
    label: 'Field for date range',
  },
  {
    icon: 'person',
    label: 'Field for contacts',
  },
  {
    icon: 'location_on',
    label: 'Field for address',
  },
]

export const addTicketFilters: FieldType[] = [
  {
    icon: 'event',
    label: 'Date created',
  },
  {
    icon: 'domain',
    label: 'Organization',
  },
  {
    icon: 'event',
    label: 'Date solved',
  },
  {
    icon: 'thumb_up',
    label: 'Satisfaction',
  },
  {
    icon: 'reviews',
    label: 'Satisfaction comment',
  },
  {
    icon: 'inbox',
    label: 'Inbox',
  },
  {
    icon: 'event',
    label: 'Due date',
  },
  {
    icon: 'text_fields',
    label: 'Subject',
  },
  {
    icon: 'sell',
    label: 'Tags',
  },
]

export const addOrganizationFilters: FieldType[] = [
  {
    icon: 'location_on',
    label: 'Field for address',
  },
]

export const tags: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Contains at least one of these', radioValue: 'At least one', field: 'tags' },
    { label: 'Contains all of these', radioValue: 'All', field: 'tags' },
    { label: 'Contains none of these', radioValue: 'Not', btnPreLabel: 'not', field: 'tags' },
  ],
  options: [
    'payment',
    'custom',
    'update',
    'test',
    'vip',
  ],
}

export const checkboxOrganizations: FilterType = {
  value: [],
  options: [
    'Mitsubishi',
    'eBay',
    'Apple',
    'L\'Oréal',
    'MasterCard',
    'McDonald’s',
    'Nintendo',
    'Microsoft',
  ],
}
export const organizations: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'organizations' },
    { label: 'Is not', radioValue: 'not', field: 'organizations', btnPreLabel: 'not' },
  ],
  options: [
    {
      name: 'Re Starbucks',
      image: 'images/logos/starbucks.svg',
      domain: 'starbucks.com',
    },
    {
      name: 'Remac',
      image: 'images/logos/mcdonalds.svg',
      domain: 'mcdonalds.com',
    },
    {
      name: 'Louis ReVuitton',
      color: 'orange',
    },
    {
      name: 'REBM',
      image: 'images/logos/ibm.svg',
      domain: 'ibm.com',
    },
    {
      name: 'reBay',
      image: 'images/logos/ebay.svg',
      domain: 'ebay.com',
    },
    {
      name: 'Sunflaower Limited',
    },
  ],
}
export const segments: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'segments' },
    { label: 'Is not', radioValue: 'not', field: 'segments', btnPreLabel: 'not' },
  ],
  options: [
    {
      name: 'Sales',
      subtitle: '101 contacts',
    },
    {
      name: 'United States',
      subtitle: '25 contacts',
    },
    {
      name: 'Custom list 1',
      subtitle: '60 contacts',
    },
    {
      name: 'Friday reparty',
      subtitle: '43 contacts',
    },
    {
      name: 'Summer resale',
      subtitle: '121 contacts',
    },
    {
      name: 'First recontact list',
      subtitle: '11 contacts',
    },
  ],
}
export const lists: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'lists' },
    { label: 'Is not', radioValue: 'not', field: 'lists', btnPreLabel: 'not' },
  ],
  options: [
    {
      name: 'Sales',
      subtitle: '101 contacts',
    },
    {
      name: 'United States',
      subtitle: '25 contacts',
    },
    {
      name: 'Custom list 1',
      subtitle: '60 contacts',
    },
    {
      name: 'Friday reparty',
      subtitle: '43 contacts',
    },
    {
      name: 'Summer resale',
      subtitle: '121 contacts',
    },
    {
      name: 'First recontact list',
      subtitle: '11 contacts',
    },
  ],
}

export const domains: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Contains any of these', radioValue: '1', field: 'domains' },
    { label: 'Contains none of these', radioValue: '2', field: 'domains', btnPreLabel: 'not' },
    { label: 'Begins with', radioValue: '3', field: 'domains', btnPreLabel: 'begins with' },
    { label: 'Ends with', radioValue: '4', field: 'domains', btnPreLabel: 'ends with' },
  ],
  options: [
    'org.eu',
    'company.com',
    '.au',
    'testing-domain',
  ],
}

export const countries: FilterType = {
  value: [],
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'countries' },
    { label: 'Is not', radioValue: 'not', field: 'countries', btnPreLabel: 'not' },
  ],
  options: allCountries,
}
export const subjects: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Contains any of the words', radioValue: '1', field: 'subjectEditableBadges' },
    { label: 'Does not contain any words', radioValue: '2', btnPreLabel: 'not', field: 'subjectEditableBadges' },
    { label: 'Contains phrase', radioValue: '3', field: 'textarea' },
    { label: 'Does not contain phrase', radioValue: '4', btnPreLabel: 'not', field: 'textarea' },
    { label: 'Exactly matches', radioValue: '5', field: 'textarea' },
  ],
}
export const requesters: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'requesters' },
    { label: 'Is not', radioValue: 'not', field: 'requesters', btnPreLabel: 'not' },
  ],
  options: assigneeOptions,
}
export const followers: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'requesters' },
    { label: 'Is not', radioValue: 'not', field: 'requesters', btnPreLabel: 'not' },
  ],
  options: assigneeOptions,
}
export const associated: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Is', radioValue: 'is', field: 'associated' },
    { label: 'Is not', radioValue: 'not', field: 'associated', btnPreLabel: 'not' },
  ],
  options: associateOptions,
}

export const dateCreated: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'All time', radioValue: '1', modelValue: 'All time' },
    { label: 'Last 30 days', radioValue: '2', modelValue: 'Last 30 days' },
    { label: 'Last 7 days', radioValue: '3', modelValue: 'Last 7 days' },
    { label: 'Yesterday', radioValue: '4', modelValue: 'Yesterday' },
    { label: 'Today', radioValue: '5', modelValue: 'Today' },
    { label: 'Time from now', radioValue: '6', field: 'timeFromNow', modelValue: null },
    { label: 'Custom range', radioValue: '7', field: 'customRange', modelValue: null },
  ],
}
export const dueDate: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { label: 'Any due date', radioValue: 'anyDueDate', modelValue: 'Any' },
    { label: 'Now overdue', radioValue: 'nowOverdue', modelValue: 'Now overdue' },
    { label: 'Overdue more than', radioValue: 'overdueMoreThan', field: 'overdueMoreThan', modelValue: null },
    { label: 'Due in next', radioValue: 'dueInNext', field: 'dueInNext', modelValue: null },
    { label: 'Time from now', radioValue: 'timeFromNow', field: 'timeFromNow', modelValue: null },
    { label: 'Custom range', radioValue: 'customRange', field: 'customRange', modelValue: null },
    { label: 'No due date', radioValue: 'noDueDate', modelValue: 'Not set' },
  ],
}

export const inboxFilter: FilterType = {
  value: [],
  options: [
    'morissette.ursulaюdsdf@hogtmail.com',
    'oswaldo.fisher@gmail.com',
    'trisha88@hyatt.com',
    'corrine21@gmail.com',
    'hugh72@willms.com',
  ],
}

export const twoStepAuthFilter: FilterType = {
  value: [],
  options: [
    'Enabled',
    'Disabled',
  ],
}

export const satisfactionFilter: FilterType = {
  value: [],
  options: [
    { name: 'Good', icon: 'thumb_up', iconClass: 'success--text' },
    { name: 'Poor', icon: 'thumb_down', iconClass: 'red--text' },
    { name: 'None' },
  ],
}

export const satisfactionCommentFilter: FilterType = {
  value: [],
  options: ['With comment', 'Without comment'],
}

export const contactStatusesFilter: FilterType = {
  value: [],
  options: contactsStatusOptions,
}

export const amount: FilterType = {
  value: null,
}

export const ticketStatusesFilter: FilterType = {
  value: [],
  options: ticketsStatusOptions,
}

export const organizationTypesFilter: FilterType = {
  value: [],
  options: organizationTypesOptions,
}

export const assignee: FilterType = {
  value: [],
  options: assigneeOptions,
}

export const channels: FilterType = {
  value: [],
  options: ['Tickets', 'Live chat', 'Calls'],
}

export const createdBy: FilterType = {
  value: [],
  options: createdByOptions,
}

export const priority: FilterType = {
  value: [],
  options: ['400', '300', '200', '100'],
}

export const activities: FilterType = {
  value: [],
  options: activityOptions,
}

export const activitiesChats: FilterType = {
  value: [],
  options: [
    'Mobile chats',
    'Whatsapp chats',
    'Facebook messenger chats',
  ],
}

export const activitiesEmails: FilterType = {
  value: [],
  options: [
    'Outlook',
    'Gmail',
  ],
}

export const activitiesCalls: FilterType = {
  value: [],
  options: [
    'Finished',
    'Missed',
    'Inbound',
  ],
}

export const files: FilterType = {
  value: [],
  options: [
    'xls',
    'zip',
  ],
}

export const campaignStatusesFilter: FilterType = {
  value: [],
  options: campaignStatusOptions,
}

export const campaignTypesFilter: FilterType = {
  value: [],
  options: ['Once', 'Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly'],
}

export const reportsNumbers: FilterType = {
  value: [],
  options: reportsNumbersOptions,
}

export const reportsAccounts: FilterType = {
  value: null,
  radioValue: null,
  radioOptions: [
    { fullName: 'All accounts (8)', avatarColor: 'gray', avatarIcon: 'gray', icon: 'tmi-people', radioValue: '1', modelValue: 'All accounts', type: 'user' },
    { fullName: 'Arlene McCoy', email: 'grolschie@mac.com', avatarColor: 'red', radioValue: '2', modelValue: 'Arlene McCoy', type: 'user' },
    { fullName: 'Sava Nguyen', email: 'amaranth@outlook.com', avatarColor: 'green', radioValue: '3', modelValue: 'Sava Nguyen', type: 'user' },
    { fullName: 'Darlene Miles', email: 'hwestiii@mac.com', avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg', avatarColor: 'orange', radioValue: '4', modelValue: 'Darlene Miles', type: 'user' },
    { fullName: 'Esther Pena', avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', avatarColor: 'blue', radioValue: '5', modelValue: 'Esther Pena', type: 'user' },
    { fullName: 'Flores Juanita', email: 'hwestiii@mac.com', avatar: 'https://randomuser.me/api/portraits/women/33.jpg', avatarColor: 'mint', radioValue: '6', modelValue: 'Flores Juanita', type: 'user' },
    { fullName: 'Nguyen Shane', avatar: 'https://randomuser.me/api/portraits/men/14.jpg', avatarColor: 'red', radioValue: '7', modelValue: 'Nguyen Shane', type: 'user' },
    { fullName: 'Helen Arthur', email: 'hwestiii@mac.com', avatar: 'https://randomuser.me/api/portraits/women/43.jpg', avatarColor: 'green', radioValue: '8', modelValue: 'Helen Arthur', type: 'user' },
    { fullName: 'Wade Warren', avatar: 'https://randomuser.me/api/portraits/men/12.jpg', avatarColor: 'blue', radioValue: '9', modelValue: 'Wade Warren', type: 'user' },
  ],
}

export const reportsWidgets: FilterType = {
  value: [],
  options: [
    { name: 'Main marketing website' },
    { name: 'Support portal' },
    { name: 'Careers page' },
    { name: 'Community website' },
    { name: 'Personal blog', inactive: true },
  ],
}

export const reportsChannels: FilterType = {
  value: [],
  options: reportsChannelsOptions,
}

export const boards: FilterType = {
  value: [],
  options: boardOptions,
}

export const pipelines : FilterType = {
  value: [],
  options: pipelinesOptions,
}

export const campaignSentMessagesStatusFilter: FilterType = {
  value: [],
  options: campaignSentMessagesOptions,
}
